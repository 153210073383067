.creation-projet-page {
  h1 {
    color: #11190c;

    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 100% */
    letter-spacing: -0.15rem;
  }

  h2 {
    //margin-bottom: 2rem;
    color: #71717a;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02625rem;
  }

  .h2-delete {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .container-name-website {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3.75rem;
    .label-input {
      display: flex;
      flex-direction: column;
    }
    input {
      padding: 0.5rem 3.5rem 0.5rem 0.75rem;
      border: 1px solid #cbd5e1;
      width: 24rem;
      border-radius: 0.375rem;
      font-size: 15px;
      letter-spacing: -0.03rem;
      font-family: "InterRegular";
    }
  }

  .container-textarea {
    display: flex;
    flex-direction: column;

    p {
      color: #64748b;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      margin-top: 0.3rem;
    }
    textarea {
      border: 1px solid #ced4da;
      width: 800px;
      height: 300px;
      border-radius: 5px;
      padding: 12px;
      font-size: 15px;
      letter-spacing: -0.03rem;
      font-family: "InterRegular";
    }
    textarea:focus {
      outline: 1px solid $primary-color; /* Supprime l'indicateur de focus */
    }

    label {
      margin-top: 2.5rem;
    }
  }

  button {
    font-family: "InterMedium";
    font-size: 0.875rem;
    margin-top: 1rem;
    line-height: 1.5rem;
    width: 800px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: $primary-color;
    color: white;
    border: none;
    cursor: pointer;
  }

  button:disabled {
    background-color: #8181fa;
    cursor: default;
  }

  .guide-link {
    margin-left: 3px;
    color: $primary-color;
  }

  .delete-btn {
    width: 10.5rem;
    height: 36px;
    border-radius: 0.25rem;
    border: none;
    background: $error;
  }
}
.modal {
  width: 35rem;
  height: 7rem;
  display: flex;
  font-weight: 600;
  flex-direction: column;

  p {
    margin-top: 1rem;
  }
  .delete-btn {
    margin-left: 1rem;
    width: 6rem;
    height: 36px;
    border-radius: 0.25rem;
    border: none;
    background: $error;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #f57676;
    }
  }

  .cancel-btn {
    height: 36px;
    border-radius: 0.25rem;
    border: none;
    width: 6rem;
    padding: 0.5rem;
    color: rgb(105, 105, 105);
    cursor: pointer;
    &:hover {
      background-color: #dfdfdf;
    }
  }

  .btn-div {
    display: flex;
    justify-content: right;
    margin-top: 2rem;
  }
}
