.nav-inspection {
  margin-top: 15px;
  display: inline-flex;
  padding: 0.3125rem;
  align-items: flex-start;
  border-radius: 0.375rem;
  background: var(--slate-100, #f1f5f9);

  a {
    display: flex;
    color: #334155;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
    padding: 0.375rem 0.75rem;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.1875rem;
    background: #f1f5f9;
    text-decoration: none;
    &:hover {
      color: $secondary-color;
      /*  border-color: #6935d7; */
    }
  }

  a.active {
    border-radius: 0.1875rem;
    background: #fff;
  }
}
