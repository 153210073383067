.homepage {
  @keyframes slideIn {
    from {
      transform: translateX(-5vw);
    }
    to {
      transform: translateX(0);
    }
  }

  h1 {
    color: #11190c;

    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 100% */
    letter-spacing: -0.15rem;
    overflow: hidden;
    white-space: nowrap;
    animation: slideIn 1s ease-out;
  }

  h2 {
    margin-bottom: 2rem;
    color: #71717a;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02625rem;
  }

  .all-stats {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: auto;
    grid-gap: 20px;
    align-items: center;
  }

  .chart-container {
    padding: 20px;
    background-color: white;
    margin-top: 2rem;
    border-radius: 8px;
    border: 1px solid #dee2e6;
  }

  .container-stats {
    width: 279px;
    height: 102px;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #000;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid #dee2e6;
    padding: 10px;
    display: flex;
    align-items: center;
    .img-p {
      margin-left: 1rem;
    }

    img {
      width: 28px;
      margin-left: 1rem;
    }

    span {
      color: #868e96;
      font-size: 12px;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-weight: 700;
      text-transform: uppercase;
    }

    p {
      color: inherit;
      font-size: 20px;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-weight: 700;
    }
  }
}
