.documentation-page {
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  h1 {
    color: #11190c;

    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 2rem;
    letter-spacing: -0.05rem;
  }
  h2 {
    color: #71717a;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    letter-spacing: -0.02625rem;
  }
  .container-video-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 125% */
      text-decoration-line: underline;
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */
    }
    .container-text {
      padding: 2rem;
      p {
        color: #4a3958;

        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
        margin-bottom: 0.5rem;
      }

      .div-suivi {
        margin-top: 5rem;
      }
    }
    .div-domain {
      display: flex;
      align-items: center;

      h3 {
        margin-bottom: 0.5rem;
      }

      span {
        color: #4a3958;

        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
        margin-bottom: 0.5rem;
      }
      .p-domain {
        max-width: 500px;
      }
    }

    .container-video {
    }
  }
  .video {
    border-radius: 0.5rem;
    border: 8px solid rgba(230, 215, 255, 0.46);
    width: 600px;
    height: auto;
  }

  .SC-parameters {
    display: flex;
    display: row;
    align-items: center;
    justify-content: space-evenly;
    img {
      border-radius: 7px;
    }
  }

  .container-tutorials {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 1.6rem;
      font-weight: 600;
      margin: 20px 0px;
    }
    img {
      max-width: 1100px;
      padding: 40px 0px;
      border-radius: 30px;
    }

    span {
      max-width: 1100px;
    }
    a {
      color: $primary-color;
      margin-left: 5px;
      text-decoration: underline;
    }
  }
}
