.navbar {
  display: flex;
  padding: 10px 45px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 3.75rem;
  border-bottom: 1px solid #e7e7e9;

  .btn-new-project {
    font-family: "InterMedium";
    font-size: 0.875rem;
    margin-right: 1rem;
    line-height: 1.5rem;
    width: 9rem;
    height: 36px;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #5253f9;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #6363ff;
    }
  }

  .user-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .date {
      display: flex;
      align-items: center;
      margin-left: 20px;

      p {
        margin-left: 20px;
        color: #242423;

        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3rem;
        letter-spacing: -0.04375rem;
      }

      .container-calendar {
        width: 2.1875rem;
        height: 2.1875rem;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3.125rem;
        background: #f6f6f6;

        img {
          width: 24px;
        }
      }
    }
    .img-user {
      width: 37px;
    }

    .user-infos {
      margin-left: 8px;
      line-height: 17px;
      h2 {
        color: #11190c;
        font-family: "InterMedium";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;

        letter-spacing: -0.05625rem;
      }

      span {
        color: #71717a;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;

        letter-spacing: -0.0225rem;
      }
    }
  }
  .container-projects {
    display: flex;
  }
}
