$primary-color: #5253f9;
$secondary-color: #2f2b43;
$tertiary-color: #e7e7e7;
$quaternary-color: #999ca6;
$hover-primary: #6363ff;
//$quaternary-color: #b6c0ce;
$quinary-color: #333333;
$hover-primary-color: #1348db;
$background-color: #f8f9fa;
$sucess: #4e8565;
$error: #dd5c64;
$waiting: #e5b687;
