.url-table {
  margin-top: 1rem;

  thead tr:first-child th:first-child {
    border-radius: 0.3125rem 0rem 0rem 0rem;
  }

  thead tr:first-child th:last-child {
    border-radius: 0rem 0.3125rem 0rem 0rem;
  }
  //   tbody tr:last-child td:first-child {
  //     border-radius: 0px 0px 0px 10px;
  //   }
  //   tbody tr:last-child td:last-child {
  //     border-radius: 0px 0px 10px 0px;
  //   }

  thead {
    background-color: $primary-color;
    border-radius: 50px 10px 0px 0px;
    // border: 0.5px solid #c4c4c4;
    height: 3rem;

    tr {
    }
    th {
      color: #fff;
      text-align: left;
      padding: 10px 10px 10px 10px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.02438rem;
    }
  }

  tbody {
    background-color: white;
    border: 0.5px solid #dedede;
    tr {
    }

    td {
      padding: 0px 10px;
      color: #4f4f4f;
      padding: 10px 10px 10px 10px;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.02438rem;
      height: 3rem;
    }
  }

  .td-url {
    max-width: 20rem;
  }

  .th-1st {
    padding-left: 15px;
  }

  .th-last {
    padding-right: 15px;
  }
  .td-last {
    padding-right: 15px;
  }

  .td-1st {
    padding-left: 15px;
  }
}
