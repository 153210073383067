.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 5px;
  cursor: pointer;
  color: gray;
  background-color: white;
  border: 1px solid rgb(196, 196, 196);
  padding: 1px 7px;

  &:hover {
    color: $primary-color;
  }
}

.pagination .active {
  font-weight: bold;
  color: $primary-color;
  border: 1px solid $primary-color;
}
