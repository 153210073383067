.suivi-indexation {
  h1 {
    color: #11190c;

    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 100% */
    letter-spacing: -0.15rem;
  }

  h2 {
    //margin-bottom: 2rem;
    color: #71717a;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02625rem;
  }

  .no-data {
    width: 800px;
    margin-top: 5rem;
    text-align: center;
  }

  .search-export {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  .search-bar {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 8px;
    letter-spacing: -0.05em;
    color: $secondary-color;
    background-color: white;
    width: 250px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 15px;
    letter-spacing: -0.03rem;
    font-family: "InterRegular";
  }

  .btn-export {
    font-family: "InterMedium";
    font-size: 0.875rem;

    line-height: 1.5rem;
    width: 9rem;
    height: 36px;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #5253f9;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #6363ff;
    }
  }

  .statut-demande {
    margin-top: 14px;
    font-size: 11px;
    height: 20px;
    line-height: 18px;
    text-decoration: none;
    padding: 0px 10.6667px;
    box-sizing: border-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    text-transform: uppercase;
    border-radius: 32px;
    font-weight: 700;

    cursor: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    background: $waiting;
    color: rgb(255, 255, 255);
  }
}
