.inspection-page {
  h1 {
    color: #11190c;

    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 100% */
    letter-spacing: -0.15rem;
  }

  h2 {
    //margin-bottom: 2rem;
    color: #71717a;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02625rem;
  }
  .container-textarea {
    display: flex;
    flex-direction: column;

    textarea {
      border: 1px solid #ced4da;
      width: 800px;
      height: 300px;
      border-radius: 5px;
      padding: 12px;
      font-size: 15px;
      letter-spacing: -0.03rem;
      font-family: "InterRegular";
    }
    textarea:focus {
      outline: 1px solid $primary-color; /* Supprime l'indicateur de focus */
    }

    label {
      letter-spacing: -0.03rem;

      margin-top: 1rem;
      margin-bottom: 0.2rem;
    }
  }

  button {
    font-family: "InterMedium";
    font-size: 0.875rem;
    margin-top: 1rem;
    line-height: 1.5rem;
    width: 800px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: $primary-color;
    color: white;
    border: none;
    cursor: pointer;
  }

  button:disabled {
    background-color: #8181fa;
    cursor: default;
  }

  .invalid-urls {
    margin-top: 1rem;
    h3 {
      text-decoration: underline;
      color: $error;
    }
    li {
      font-size: 0.95rem;
    }
  }
}
